import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {ClientIdAndNameTO} from 'api/entities';
import {forkJoin, of, switchMap} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {UserSessionService} from '../../shared/entities/auth/user-session.service';
import {DeviceActivationEntity} from '../../shared/entities/devices/device-activation.entity';
import {GroupsEntity} from '../../shared/entities/groups/groups.entity';
import {QrNavigationEntity} from '../../shared/entities/qr-navigation/qr-navigation.entity';
import {SystemEntity} from '../../shared/entities/system/system.entity';
import {SystemInfoI} from '../../shared/entities/system/system.interface';
import {CurrentUser} from '../../shared/entities/user/avelon-user.interface';
import {Alcedo7User} from '../../shared/entities/user/avelon-user.service';
import {CurrentUserEntity} from '../../shared/entities/user/current-user.entity';
import {SplashScreenComponent} from './splash-screen/splash-screen.component';

@Component({
  selector: 'alc-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
  standalone: true,
  imports: [SplashScreenComponent]
})
export class LandingPageComponent implements OnInit {
  constructor(
    private oidcSecurityService: OidcSecurityService,
    private router: Router,
    private currentUserEntity: CurrentUserEntity,
    private groupsEntity: GroupsEntity,
    private systemEntity: SystemEntity,
    private deviceActivationEntity: DeviceActivationEntity,
    private qrNavigationEntity: QrNavigationEntity
  ) {}

  ngOnInit() {
    this.oidcSecurityService
      .isAuthenticated()
      .pipe(take(1))
      .subscribe({
        next: isAuthenticated => {
          if (isAuthenticated) {
            this.navigateUser();
          } else {
            this.oidcSecurityService.authorize();
          }
        },
        error: () => {
          if (location.search.startsWith('?state=')) {
            // ERROR could not find matching config for state
            // this happens when resetting password and user opens email link in a new browser tab
            // the oidc library should authorize() again instead of throwing an error
            this.oidcSecurityService.authorize();
          }
        }
      });
  }

  private navigateUser(): void {
    const originalClientId = Alcedo7User.selectedClient?.id;
    forkJoin([this.currentUserEntity.getClients(), this.currentUserEntity.getCurrentUserDefault(), this.systemEntity.getStaticInfo()]).subscribe(
      ([clients, currentUser, staticInfo]: [ClientIdAndNameTO[], CurrentUser, SystemInfoI]) => {
        if (originalClientId && currentUser.clientId !== originalClientId) {
          this.groupsEntity.invalidateGroups();
        }

        const ref = UserSessionService.getRef();
        const refSplit = ref?.split('/') || [];
        const isClientRef = refSplit[1] === 'client';
        const refClientId = +refSplit[2];
        const hasClientAccess = isClientRef && clients.find(c => c.id === refClientId);
        if (ref && hasClientAccess) {
          location.href = location.origin + ref;
        } else if (UserSessionService.isOnSitePanel(staticInfo.deploymentType)) {
          this.navigatePanel(currentUser);
        } else {
          this.navigateToClient(currentUser);
        }
      }
    );
  }

  private navigatePanel(currentUser: CurrentUser): void {
    this.deviceActivationEntity
      .panelInfo()
      .pipe(
        switchMap(panelInfo => {
          if (panelInfo.startDashboardId) {
            return this.qrNavigationEntity
              .getDashboardNavigationParams(panelInfo.startDashboardId)
              .pipe(map(params => ({dashboardId: panelInfo.startDashboardId, ...params})));
          } else {
            return of(false);
          }
        })
      )
      .subscribe({
        next: (response: false | {clientId: number; groupId: number; dashboardId: number}) => {
          if (response) {
            this.router.navigate(['client', response.clientId, 'dashboards', 'group', response.groupId, 'dashboard', response.dashboardId]);
          } else {
            this.navigateToClient(currentUser, false);
          }
        },
        error: () => this.navigateToClient(currentUser, false)
      });
  }

  private navigateToClient(currentUser: CurrentUser, showNavigation = true): void {
    this.router.navigate(['client', currentUser.clientId, 'dashboards'], {state: {showNavigation}});
  }
}
