import {HttpClient} from '@angular/common/http';
import {Translation, TranslocoLoader} from '@jsverse/transloco';
import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string) {
    return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
  }
}
