import {provideHttpClient, withInterceptors} from '@angular/common/http';
import {importProvidersFrom} from '@angular/core';
import {
  ErrorStateMatcher,
  MAT_DATE_LOCALE,
  MAT_RIPPLE_GLOBAL_OPTIONS,
  RippleGlobalOptions,
  ShowOnDirtyErrorStateMatcher
} from '@angular/material/core';
import {MatDialogModule} from '@angular/material/dialog';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions} from '@angular/material/form-field';
import {MAT_SELECT_CONFIG, MatSelectConfig} from '@angular/material/select';
import {MAT_TOOLTIP_DEFAULT_OPTIONS} from '@angular/material/tooltip';
import {bootstrapApplication} from '@angular/platform-browser';
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {provideRouter, withRouterConfig} from '@angular/router';
import {provideServiceWorker} from '@angular/service-worker';
import {provideTransloco, translocoConfig} from '@jsverse/transloco';
import {authInterceptor, provideAuth} from 'angular-auth-oidc-client';
import {CgBusyModule} from 'angular-busy2';
import {ApolloModule} from 'apollo-angular';
import {NgxPermissionsModule} from 'ngx-permissions';
import {ToastrModule} from 'ngx-toastr';
import {ApolloGraphQLProvider} from './app/apollo-graphql.provider';
import {AppComponent} from './app/app.component';
import {appRoutes} from './app/app.routes';
import {AuthConfigurations} from './app/auth-config.constant';
import {cachingInterceptor} from './app/core/interceptors/caching.interceptor';
import {errorInterceptor} from './app/core/interceptors/error.interceptor';
import {specialAuthInterceptor} from './app/core/interceptors/special-auth.interceptor';
import {trimInterceptor} from './app/core/interceptors/trimming.interceptor';
import {lang} from './app/product-information.constant';
import {LUXON_ADAPTER_PROVIDER} from './app/shared/adapters/luxon-date-adapter/luxon-date-adapter.providers';
import {UserSessionService} from './app/shared/entities/auth/user-session.service';
import {AlcedoUserLanguages} from './app/shared/properties/user/user-languages.enum';
import {TranslocoHttpLoader} from './app/transloco-loader.service';
import {environment} from './environments/environment';

const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
  animation: {
    enterDuration: 300,
    exitDuration: 0
  }
};
const appearance: MatFormFieldDefaultOptions = {
  appearance: 'fill'
};

bootstrapApplication(AppComponent, {
  providers: [
    provideAnimationsAsync(),
    provideHttpClient(withInterceptors([authInterceptor(), trimInterceptor, cachingInterceptor, specialAuthInterceptor, errorInterceptor])),
    provideRouter(appRoutes, withRouterConfig({paramsInheritanceStrategy: 'always', onSameUrlNavigation: 'reload'})),
    provideAuth({config: AuthConfigurations}),
    importProvidersFrom(
      CgBusyModule.forRoot({backdrop: true}),
      ApolloModule,
      NgxPermissionsModule.forRoot(),
      ToastrModule.forRoot({
        positionClass: 'toast-bottom-right',
        enableHtml: true,
        maxOpened: 5,
        preventDuplicates: true,
        timeOut: 10000,
        extendedTimeOut: 5000
      })
    ),
    provideServiceWorker('ngsw-worker.js', {
      enabled: environment.production && !UserSessionService.isReporting && !location.pathname.startsWith('/export')
    }),
    ApolloGraphQLProvider,
    provideTransloco({
      config: translocoConfig({
        availableLangs: Object.values(AlcedoUserLanguages),
        defaultLang: 'en',
        fallbackLang: 'en',
        missingHandler: {
          useFallbackTranslation: true
        },
        reRenderOnLangChange: true,
        prodMode: environment.production
      }),
      loader: TranslocoHttpLoader
    }),
    {
      provide: ErrorStateMatcher,
      useClass: ShowOnDirtyErrorStateMatcher
    },
    {
      provide: MAT_RIPPLE_GLOBAL_OPTIONS,
      useValue: globalRippleConfig
    },
    {
      provide: MAT_DATE_LOCALE,
      useFactory: () => lang.locale
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: appearance
    },
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: {disableTooltipInteractivity: true}
    },
    {
      provide: MAT_SELECT_CONFIG,
      useValue: {hideSingleSelectionIndicator: true} as MatSelectConfig
    },
    LUXON_ADAPTER_PROVIDER,
    importProvidersFrom(MatDialogModule)
  ]
}).catch(err => console.error(err));
